// NewsCard
import "../NewsPage.css";
import { NavLink } from "react-router-dom";

const tempImage =
	"https://media.istockphoto.com/id/1357365823/vector/default-image-icon-vector-missing-picture-page-for-website-design-or-mobile-app-no-photo.jpg?s=612x612&w=0&k=20&c=PM_optEhHBTZkuJQLlCjLz-v3zzxp-1mpNQZsdjrbns=";

export default function NewsCard({ article }) {
	const { id, title, image, date, category } = article;

	// format date
	const newDate = new Date(date);
	const month = newDate.toLocaleDateString("en-US", { month: "long" });
	const year = newDate.toLocaleDateString("en-US", { year: "numeric" });

	return (
		<NavLink to={`/news/${id}`}>
			<div className="event-card">
				<div className="detail-left">
					<img
						src={image ? image : tempImage}
						className="no-groups-img-read"
						alt="temporary"
					/>
				</div>

				<div className="detail-right">
					<div className="about-section brown-text-small">{`${month} ${year}`}</div>
					<h3 className="detail-right-name">{title}</h3>

					{category && category !== "" && (
						<div className="about-section news-about-mobile">
							{category}
						</div>
					)}
				</div>
			</div>
		</NavLink>
	);
}
