// imports
import healthOutreachImage from "../../../Assets/Events/Other/what-1.png";
import { addWellnessClubEvent, getEventImagesPath } from "../utils";


const eventFolder = "HealthOutreachImages"

const commonData = {
	title: "Health Outreach",
	applyLink: "",
	componentLink: "healthOutreachEvent",
	eventImage: healthOutreachImage,
	description: [
		"The Hope Health Initiative is dedicated to delivering personalized consultation, health education, diagnostics, and medications to rural and underserved communities. Our mission is to ensure that individuals in these areas receive timely and quality healthcare. By bringing essential health services directly to those in need, we aim to bridge the gap in healthcare access and improve the overall well-being of these communities.",
	],
	eventStartTime: "9am",
	eventEndTime: "4pm",
};

//main
/**
 * eventDate: "MM/DD/YYYY"
 * eventTime: "HH:MM" + ("am" OR "pm")
 */
export const HealthOutreachData = [
	{
		...commonData,
		id: "ho1",
		details: "Toase in collaboration with Komfo Anokye Teaching Hospital (KATH) and Kwame Nkrumah University of Science and Technology (KNUST)",
		location: "Toase, Ghana",
		applyLink: "",
		videoLink: "",
		eventStartDate: "05/18/2024",
		eventEndDate: "05/18/2024",
		images: [
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/IMG_4483.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/vIMG_4462.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/IMG_4446.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/IMG_4439.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/IMG_4410.jpg",
		],
		visible: false,
	},
	{
		...commonData,
		id: "ho2",
		details: "Kumawa Besoro",
		location: "Kumawa Besoro, Ghana",
		applyLink: "",
		eventStartDate: "03/13/2024",
		eventEndDate: "03/13/2024",
		images: [
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/test-images/IMG_4410.jpg",
		],
		visible: true,
	},
	{
		...commonData,
		id: "ho3",
		details: "Mpaepaemu Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "06/21/2024",
		eventEndDate: "06/21/2024",
		images: [
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240622-WA0027.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240709-WA0028.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0084.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0085.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0086.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0087.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0088.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0089.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0090.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Mpaepaemu+community/IMG-20240820-WA0091.jpg",
		],
		visible: true,
	},
	{
		...commonData,
		id: "ho4",
		details: "at Krufrom Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "07/12/2024",
		eventEndDate: "07/12/2024",
		eventImage: "https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240713-WA0033.jpg",
		images: [
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240713-WA0033.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0004.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0005.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0006.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0007.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0008.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0009.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0010.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0011.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0012.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0013.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0014.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0015.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0016.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0017.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0018.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0019.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0020.jpg",
			"https://okb-images-s3-bucket.s3.amazonaws.com/Event-Images/HealthOutreachImages/Krufrom+Community/IMG-20240715-WA0021.jpg",
		],
		visible: true,
	},
	{
		...commonData,
		id: "ho5",
		details: "at Camp Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "06/28/2024",
		eventEndDate: "06/28/2024",
		images: getEventImagesPath(eventFolder, "Camp+Community", 19),
		eventImage: getEventImagesPath(eventFolder, "Camp+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho6",
		details: "at Atia Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "06/14/2024",
		eventEndDate: "06/14/2024",
		images: getEventImagesPath(eventFolder, "Atia+Community", 8),
		eventImage: getEventImagesPath(eventFolder, "Atia+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho8",
		details: "at Apemso Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "08/09/2024",
		eventEndDate: "08/09/2024",
		images: getEventImagesPath(eventFolder, "Apemso+Community", 19),
		eventImage: getEventImagesPath(eventFolder, "Apemso+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho9",
		details: "at Djato Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "07/25/2024",
		eventEndDate: "07/25/2024",
		images: getEventImagesPath(eventFolder, "Djato+Community", 14),
		eventImage: getEventImagesPath(eventFolder, "Djato+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho10",
		details: "at Antobea Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "07/23/2024",
		eventEndDate: "07/23/2024",
		images: getEventImagesPath(eventFolder, "Antobea+Community", 20),
		eventImage: getEventImagesPath(eventFolder, "Antobea+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho11",
		details: "at Asempanaye Community",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "07/24/2024",
		eventEndDate: "07/24/2024",
		images: getEventImagesPath(eventFolder, "Asempanaye+Community", 18),
		eventImage: getEventImagesPath(eventFolder, "Asempanaye+Community", 1),
		visible: true,
	},
	{
		...commonData,
		id: "ho12",
		details: "at ...",
		location: "Ghana",
		applyLink: "",
		eventStartDate: "08/18/2024",
		eventEndDate: "08/18/2024",
		images: getEventImagesPath(eventFolder, "Camp+Community", 19),
		eventImage: getEventImagesPath(eventFolder, "Camp+Community", 1),
		visible: false,
	},
	addWellnessClubEvent(commonData),
];


/**
 *
 * Atia Community - 06/14/2024
Mpaepaemu - 06/21/2024
Camp Community- 06/28/2024
Krufrom Community - 07/12/2024
Antobia Community - 07/23/2024
Asempanaye Community - 07/24/2024
Djato Community - 07/25/2024
Apemso Community - 08/09/2024
 */
