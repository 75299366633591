// import images
import pic3 from "../../Assets/OurImpact/impact-7.png";
import pic4 from "../../Assets/OurImpact/impact-8.png";
import pic5 from "../../Assets/News/news-5.png";
import news4 from "../../Assets/News/news-41.png";
import news5 from "../../Assets/News/news-51.png";
import news6 from "../../Assets/News/news-6.png";
import news7 from "../../Assets/News/news-7.png";
import news8 from "../../Assets/News/news-8.png";
import news9 from "../../Assets/News/news-9.png";
import news10 from "../../Assets/News/news-10.png";
import news11 from "../../Assets/News/news-11.png";
import news12 from "../../Assets/News/news-12.png";
import news13 from "../../Assets/News/news-13.png";
import news14 from "../../Assets/News/news-14.png";
import news15 from "../../Assets/News/news-15.png";
import news16 from "../../Assets/News/news-16.png";
import news17 from "../../Assets/News/news-17.png";

// news categories
const NewsCategories = {
	news: "News",
	awards: "Awards",
	education: "Education",
	events: "Events",
	blog: "Blog",
};

/**
 * date: "MM/DD/YYYY"
 */
const MainNewsData = [
	{
		id: 1,
		title: "Knowing Your Health Report",
		link: "https://drive.google.com/file/d/1VkW7Qbo-M6ybN3PG_2uP0ruxvVyKzY9J/view?pli=1",
		image: pic3,
		date: "09/1/2023",
		category: "Education",
		details: [
			"Access to primary health care services remains a global health problem, particularly in developing countries like Ghana and others in Sub-Saharan Africa. Over 53% of Ghana’s health needs are unmet by the current healthcare services due to inadequate access to physicians and healthcare facilities. To address these challenges, the OKB Hope Foundation, through its Know Your Health Initiative, educated and trained passionate community volunteers to serve as health advocates to provide health education and primary healthcare services to their community members. This approach addresses the shortage of healthcare providers in rural and underserved areas and ensures the sustainability of our services in these communities.",
		],
		visible: true,
	},
	{
		id: 2,
		title: "Wohohiame Mental Health Initiative",
		link: "https://www.wohohiame.com/",
		image: pic4,
		date: "04/01/2024",
		category: "Education",
		details: [
			"Ghana has limited access to mental healthcare: With a population of 32 million, there are only only 61 psychiatrists and 244 psychologists. To address this gap in mental healthcare resources, we have developed the Wohohiame Wellness program. This program provides individuals struggling with mental health challenges with information that helps them cope with mental health challenges. So far, we have provided mental education and resources to over 2000 high school students. Our vision is to establish mental health clubs across high schools in Ghana.",
		],
		visible: true,
	},
	{
		id: 3,
		title: "Mobile Health Van - Annual Report 2022",
		link: "https://drive.google.com/file/d/1kx9DadG044HbwKRoBCtANkWFuw7TXGhl/view?usp=sharing",
		image: pic5,
		date: "12/01/2022",
		category: "News",
		details: [
			"At OKB Hope Foundation, we are committed to bringing primary and preventative healthcare to the doorsteps of rural and underserved communities in Ghana. We utilize a health a health van equipped with multiple point-of-care diagnostics and medical supplies that go to the underserved and remote areas in Ghana to provide medical services. With the lack of physical healthcare facilities in most rural and some urban areas, our approach seeks to ameliorate the issue of accessibility by bringing healthcare to the doorsteps of rural and underserved communities. Since the inception of the organization, we have provided care to over 4000 individuals across 50+ rural communities.",
		],
		visible: true,
	},
	{
		id: 4,
		title: "Echoing Green Finalist",
		link: "https://echoinggreen.org/news/talent-report-2022-cohort-2/#meetthefinalists",
		image: news4,
		date: "07/01/2022",
		category: "Awards",
		details: [
			"Osei Boateng, founder of OKB Hope Foundation, was selected as Echoing Green Finalist for his impactful work of improving healthcare access in rural and underserved communities in Ghana.",
			"The 48 Echoing Green Fellowship Finalists are creating pathways to a better, more equitable future for their communities and beyond.",
		],
		visible: true,
	},
	{
		id: 5,
		title: "Young Global Changers Recoupling Award",
		link: "https://www.global-solutions-initiative.org/young-global-changers/recoupling-awards-2023/",
		image: news5,
		date: "05/01/2023",
		category: "Awards",
		details: [
			"Global Solutions Initiative awards OKB Hope Foundation with the Scale Up award for their impactful work of improving healthcare access in rural and underserved communities in Ghana.",
			"Most healthcare facilities in Ghana are located in urban areas leaving several milions of people in rural communities without primary healthcare. Project aims to increase people’s access to health care by providing “mini traveling hospitals”. These are vans equipped with diagnostics and medicine and healthcare providers supplying healthcare to rural areas.",
		],
		visible: true,
	},
	{
		id: 6,
		title: "2023 CNN Hero",
		link: "https://www.cnn.com/2023/08/11/world/ghana-van-mental-health-medical-care-cnnheroes/index.html",
		image: news6,
		date: "08/01/2023",
		category: "Awards",
		details: [
			"Global Solutions Initiative awards OKB Hope Foundation with the Scale Up award for their impactful work of improving healthcare access in rural and underserved communities in Ghana.",
			"Most healthcare facilities in Ghana are located in urban areas leaving several milions of people in rural communities without primary healthcare. Project aims to increase people’s access to health care by providing “mini traveling hospitals”. These are vans equipped with diagnostics and medicine and healthcare providers supplying healthcare to rural areas.",
		],
		visible: true,
	},
	{
		id: 7,
		title: "Africa Most Impactful 100 Change Makers",
		link: "https://humanitarianawardsglobal.org/blog/e148ca05-5c30-410c-a02c-43fea6343948",
		image: news7,
		date: "02/01/2024",
		category: "Awards",
		details: [
			"Africa Most Impactful 100 Change Makers: According to Humanitarian Awards Global.",
			"The Humanitarian Awards Global is proud to unveil the esteemed list of 100 Most Impactful Change Makers in Africa for 2023, representing diverse sectors of change makers impacting lives.",
			"The Humanitarian Awards Global is steadfast in its commitment to redefining the narrative surrounding change makers through award ceremonies, educational initiatives, summits, and ranking publications.",
			"Key criteria used in the evaluation process include leadership drive, influence, consistency, and, most importantly, community and social impact.",
		],
		visible: true,
	},
	{
		id: 8,
		title: "30 Nonprofit Leaders Who Will Impact the World in 2024",
		link: "https://causeartist.com/nonprofit-leaders-2024/",
		image: news8,
		date: "04/01/2024",
		category: "Awards",
		details: [
			"Osei Kwado Boateng has dedicated his career to ensuring that quality healthcare is available to all, regardless of their socio-economic status.",
			"Born and raised in Kumasi, Ghana, where he spent the first 19 years of his life, Boateng pursued higher education with a strong focus on healthcare.",
			"The OKB Hope Foundation, founded by Boateng, aims to reduce health disparities in developing countries.",
			"It focuses on educating low-income communities about preventable health conditions such as hypertension and diabetes, emphasizing lifestyle changes to avert these diseases. The foundation collaborates with physicians and medical students to provide free education and screening in underserved areas.",
			"The ultimate goal of the OKB Hope Foundation is to lessen health inequities by promoting healthier living standards among low-income populations.",
		],
		visible: true,
	},
	{
		id: 9,
		title: "Cornell Alumnus on a Lifesaving Mission",
		link: "https://cornellsun.com/2023/10/10/cornell-alumnus-on-a-lifesaving-mission-bringing-health-care-to-rural-ghana-one-mobile-clinic-at-a-time/",
		image: news9,
		date: "10/01/2023",
		category: "News",
		details: [
			"As the full-time executive director for his nonprofit, OKB Hope Foundation, Boateng has turned his childhood promise to a reality. Starting his mission as an undergraduate at Cornell, he has aided thousands of Ghanaians by providing preventative screenings, vital mental health support and essential medication to rural communities where access to health care remains a significant challenge.",
			"Rural communities in Ghana often have no access to modern health care facilities, according to the U.S. International Trade Association. Boateng said rural Ghanaians typically must travel hours away to the hospital, and cost concerns and the availability of medical staff deter many from seeking care.",
			"Preventable chronic diseases — like diabetes and high blood pressure — constitute major health problems in rural communities. Chronic non-communicable diseases were Ghana’s leading cause of death in both 2017 and 2018, according to a 2021 research study examining mortality rates in the country between 2014 and 2018. Malaria also remains endemic and perennial throughout Ghana.",
			"Boateng began his studies at Cornell in 2016, intending to complete the pre-med track and become a medical doctor to serve communities in rural Ghana. But a human anatomy and physiology class in Bailey Hall changed his outlook on the health care situation in Ghana and how he could best address its challenges.",
			"As a fellow in the Cornell Tradition program — an initiative that coordinates the efforts of a select group of undergraduates committed to paid work and service — Boateng received a mini-grant to raise awareness about high blood pressure and diabetes in Ghana and conduct screenings for the diseases in rural communities. The grant allowed him to partner with several physicians and travel across Ghana to educate and screen residents in 2017.",
			"Boateng recalled conducting screenings in a rural community when a woman — who did not realize she had any ailments — had a high blood pressure level of over 200/120 mgHg, well over the normal level of 120/80 mmHg. When Boateng’s team rushed the woman to the hospital, the physician said that had the woman not been brought to the hospital at that time, she would have been at risk of losing her life.",
			`“That story became the turning point for me. There are a lot more people who probably are in the same situation as that woman, who have illnesses when we think they are fine,” Boateng said. “That’s when I started the OKB Hope Foundation, with the goal to create awareness about hypertension and diabetes and give people access to early screenings of these diseases.”`,
			"Boateng said that throughout his experiences speaking with patients, it was clear that a common problem they were expressing was not physical but instead psychological. In fact, only about two percent of Ghana’s 2.3 million residents living with mental health conditions receive psychiatric treatment and support from health facilities, according to the World Health Organization.",
			`In October 2022, OKB Hope Foundation launched a mental health initiative called “Wohohiame,” which means “I care for you” in Twi, a local language. The project involves traveling to various high schools around Ghana to increase awareness about mental health conditions and provide resources to students.`,
			`The foundation is also working with Cornell Hack4Impact — a club that connects student software developers with nonprofits — to design a platform where people who are experiencing mental health challenges can contact psychiatrists and psychologists to receive care.`,
			`The OKB Hope Foundation hosts a Global Healthcare Case Competition annually, with last year’s competition centering around mental health. One of the winning student-generated solutions was to create mental health clubs in high schools to provide a consistent and sustainable way for students in Ghana to access mental health resources, which the foundation is beginning to help implement.`,
		],
		visible: true,
	},
	{
		id: 10,
		title: "Cornellian is bringing care to thousands in rural Ghana",
		link: "https://alumni.cornell.edu/snack-bar/cornellian-bringing-care-thousands-rural-ghana/",
		image: news10,
		date: "11/02/2023",
		category: "News",
		details: [
			"Osei Boateng '18 has made it his life’s mission to bring health care to remote communities in Ghana. He started his nonprofit, OKB Hope Foundation, and in 2021, he converted a van into a mobile doctor’s office and started bringing health care directly to those in need.",
			"A few times a week, the mobile clinic and medical team travel long distances to remote communities in Ghana and provide free routine medical care.",
			"Since its launch, Boateng says the Hope Health Van has served more than 4,000 Ghanaians across more than 45 rural communities who otherwise don’t have easily accessible medical care.",
			`“I believe that our model can be replicated in Sub-Saharan Africa,” he said. “So, the goal is to really develop strategic partnerships and get additional mobile health vans for the communities or the regions that we want to serve.”`,
			`Boateng has been nominated for CNN Hero of the Year.`,
		],
		visible: true,
	},
	{
		id: 11,
		title: "Understanding Iron-Deficiency Anemia",
		link: "",
		image: news11,
		date: "05/01/2024",
		category: "Blog",
		details: [
			"In 2024, almost two billion people are expected to have anemia (World Health Organization, 2023). As always, we are committed to spreading awareness about health issues, so in this blog post, we'll delve into the intricacies of anemia by exploring its symptoms, causes, and treatments. We will focus on anemia caused by iron-deficiency, the most common type of anemia (American Society of Hematology, 2024).",
			"Anemia is caused by an insufficient amount of healthy red blood cells, which reduces oxygen-carrying capacity (Penn Medicine, 2024). Iron-deficiency anemia, as implied by the name, develops when the body lacks iron, causing red blood cells to die off (NHS Inform, 2023). Despite anemia’s prevalence, its symptoms often go unrecognized and untreated, which can lead to significant health risks.",
			"In Sub-Saharan Africa in general, iron-deficiency anemia is so prevalent that they are known public health issues, and in some Sub-Saharan African nations, anemia affects over 60% of children (Lemoine and Tounian, 2020). In Ghana, over 40% of pregnant women develop anemia (EDHS, E., 2016), and a study conducted in 2014 found that 78.4% of children under the age of five and 85.1% of children under the age of two had anemia (Ewusie et al., 2014). These unfortunately high numbers are caused primarily by parasitic worm infections (helminthic infections), inflammations limiting absorption of iron, and insufficient intake of iron in diets (USAID, 2023).",
			"Anemia is a significant public health concern with far-reaching implications for individuals and communities worldwide. Through education, advocacy, and support, organizations like us are working to address the root causes of anemia and improve outcomes for those affected by this condition.",
		],
		visible: false,
	},
	{
		id: 12,
		title: "Breaking Down Depression: Understanding & Support",
		link: "https://drive.google.com/file/d/1wnqhFEiT-Bo-XTUWXMS7Ovjk_uwerWs4/view?usp=sharing",
		image: news12,
		date: "05/02/2024",
		category: "Blog",
		details: [
			"Depression has been and still is a prevalent global health challenge, with around 280 million people being diagnosed as depressed worldwide (World Health Organization, 2023). We are fully committed to raising awareness about this destructive mental health condition and providing support to those in need. In this article, we will explore what depression is, its symptoms, causes, treatments, and how everyone (that means you too!), not just us, can help.",
			"Depression is a serious mental disorder that is characterized by feelings of sadness, despair, and irritability that, unlike temporary negative moods experienced by almost everyone, persist for two weeks or longer (Healthline, 2023). Over the years, diagnoses of depression in younger generations have significantly increased; in 2020, the prevalence of depression among individuals aged 12-17 years old was 16.9%, for those aged 18-25 years old it was 17.2%, and among individuals aged 26-34 years old, it was 9.9%. This is in comparison to 2015 when the corresponding rates were 12.7%, 10.3%, and 7.5%, respectively (Goodwin RD et. al., 2022). This rise in depression rates is most likely due to but is not limited to increased social media use, higher feelings of loneliness (that spiked during the isolation brought by the COVID-19 pandemic), and more (Gordon, 2023). ",
			"Women are also almost twice as likely to be diagnosed with depression than men, and this is because of both biological (hormones brought by puberty, premenstrual syndrome, pregnancy, and perimenopause; postpartum depression; etc.) and cultural factors (gender inequality, overworking, and physical and sexual abuse) (Mayo Clinic, 2019). However, this could also be because of how “masculinity” can cause men to express their symptoms differently and be less likely to seek help (Johns Hopkins Medicine, n.d.). Transgender and gender-nonconforming individuals are also more likely to experience depression and other mental health issues compared with cisgender people, as they face higher levels of discrimination and mistreatment based on their identities (Chodzen et. al., 2019). ",
			"All types of depression impact how a person thinks, feels, and handles daily activities, often leading to significant impairment in social, occupational, and other areas of functioning. Thus, it is crucial to recognize its symptoms and seek help to minimize the effects of depression on one’s life.",
			"Depression is a complex and challenging condition, but with the support of organizations like the OKB Hope Foundation, individuals can find hope and healing. Through awareness, education, support services, and advocacy, we can eliminate the stigma and silence surrounding depression and create a world where everyone has access to the care and support they need to thrive. Join us in our mission to shine a light on the shadows of depression and make a difference in the lives of those affected by depression.",
		],
		visible: true,
	},
	{
		id: 13,
		title: "Fight against UTIs",
		link: "https://drive.google.com/file/d/1y1ALR60gFP_AnsseHw2tceKFsp-bz-bn/view?usp=sharing",
		image: news13,
		date: "05/03/2024",
		category: "Blog",
		details: [
			`Amidst the wide range of medical problems, urinary tract infections (UTIs) are the second most
      prevalent disease and affect over 150 million people per year (Mlugu et. al, 2023). In this blog post,
      we’ll talk about UTI symptoms, causes, treatments, and we’re actively working to make a positive
      difference in urinary health.`,
			`Urinary tract infections (UTIs) are infections that occur in any part of the urinary system, including the
      kidneys, bladder, ureters, and urethra (Mayo Clinic, 2022). These infections can range from mild
      discomfort to severe illness and can affect anyone. UTIs are caused when bacteria enter the urethra
      and go on to infect parts of the urinary tract (Centers for Disease Control and Prevention, 2021).`,
			`In Tamale, Northern Ghana’s capital, the reported rate of infection of UTIs was 33.8% in 2022 (Karikari
        et. al, 2022). In Ghana overall, the prevalence rate of UTIs is around 15.9% (Mwang’onde, 2022).
        Pregnant women are also the most at risk for getting a UTI (Donkor et. al, 2019).`,
			`Urinary tract infections represent a significant public health concern with far-reaching implications for
      individuals and communities worldwide. By raising awareness, implementing preventive measures, and
      increasing access to quality healthcare, we can better address the challenges posed by UTIs.`,
		],
		visible: true,
	},
	{
		id: 14,
		title: "Combatting Hepatitis B: Understanding, Prevention, and Action",
		link: "https://drive.google.com/file/d/1jWjLKy4ChQp5kIbWkQ8fL8b2jJBqJ1sv/view?usp=sharing",
		image: news14,
		date: "05/04/2024",
		category: "Blog",
		details: [
			"Hepatitis viruses are the most common types of liver infections (Mayo Clinic Staff, 2024). Hepatitis B is one such virus, and despite it being both preventable and treatable, 1 million people die from it annually (Hepatitis B Foundation, 2024). In this blog post, we’ll go into the details about hepatitis B, exploring its symptoms, causes, treatments, and how we’re actively working to make a positive difference.",
			"Hepatitis B is a viral infection of the liver by the hepatitis B virus (HBV) and potentially leads to liver failure, cirrhosis, and higher chances of getting liver cancer (Mayo Clinic Staff, 2022). Hepatitis B can be acute, lasting for a few weeks or months, or chronic, persisting for over six months and potentially leading to serious complications (World Health Organization, 2024).",
			"Hepatitis B is endemic or regularly present in Ghanaian communities, with the prevalence rate being around 14.4% (Abesig et. al, 2020). Because of this, the Ghanaian government has established the National Viral Hepatitis Control Programme in 2011, which has made much progress to fight against hepatitis B; it has made available the vaccine for hepatitis B and works to vaccinate all infants against the infection (World Health Organization, 2023). In addition, the Ghana National Policy on Viral Hepatitis from 2014 outlines the work to completely eliminate hepatitis from being a public health issue through strategies like vaccination, making resources more accessible, conducting research on hepatitis, and more.",
			"Hepatitis B is a significant public health concern that will only continue to spread if left unchecked. Through education, advocacy, and support, organizations like us are working to address the challenges posed by hepatitis B and promote liver health for all. Together, let’s raise awareness, implement preventive measures, and ensure access to quality care for those affected by hepatitis B.",
		],
		visible: true,
	},
	{
		id: 15,
		title: "Battling Malaria: Awareness, Prevention, and Action",
		link: "https://drive.google.com/file/d/1TOMdA89GOtlp6D4Z1NYEvx4_sMO8ETm2/view?usp=sharing",
		image: news15,
		date: "05/05/2024",
		category: "Blog",
		details: [
			"Despite the large progress in the medical field, malaria remains a widespread global health challenge, affecting millions of lives every year. We are dedicated to raising awareness about deadly diseases such as malaria and providing support to those in need. In this blog post, we'll delve into what malaria is, its symptoms, causes, treatments, and explore how we are working to make a difference.",
			"Malaria is caused by five species of bacteria in the genus Plasmodium: P. falciparum, P. vivax, P. ovale, P. malariae, and P. knowlesi (Centers for Disease Control and Prevention, 2020). These are transmitted to humans through the bites of female Anopheles mosquitoes infected with these parasites. In Ghana, malaria is most commonly spread through P. falciparum but there also have been cases caused by P. ovale and P. malariae. Though malaria is preventable and treatable, it continues to claim hundreds of thousands of lives every year, and is especially threatening in areas with limited access to healthcare and effective medical education.",
			"Malaria is the leading cause of death in Ghana, taking about 11,557 lives in 2022 alone (World Health Organization, 2022). With our vast resources and expertise, we are at the forefront of the battle against fatal diseases like malaria.",
			"Although tools that are currently available may not have the ability to fully eradicate malaria, with cooperative efforts and the support of organizations like the OKB Hope Foundation, we can make significant strides towards its elimination. Let’s build a healthier future together through awareness, prevention, and medical intervention of diseases like malaria.",
		],
		visible: true,
	},
	{
		id: 16,
		title: "Fight against Respiratory Tract Infections",
		link: "https://drive.google.com/file/d/13qforRVXZOJVO3bay9cKdLjEQPiXizv0/view?usp=sharing",
		image: news16,
		date: "06/17/2024",
		category: "Blog",
		details: [
			`Respiratory tract infections (RTIs) are one of the most commonly seen problems in clinical medicine,
      and they cause the majority of antibiotic prescriptions (Reed, 2014). In this blog post, we’ll discuss what
      RTIs are, explore their symptoms, causes, treatments, and how the OKB Hope Foundation can work to
      make a positive difference in respiratory health.`,
			`There are two kinds of respiratory tract infections: upper respiratory tract infections (URTIs) and lower
      respiratory tract infections (LRTIs). The upper respiratory tract consists of the nose, nasal cavity,
      sinuses, and larynx (also known as the voice box), while the lower respiratory tract includes the trachea
      (windpipe), bronchi and bronchioles (which help circulate air around the lungs), and lungs (Cleveland
      Clinic, 2024).`,
			`32.5 females and 74 males per 100,000 people perished due to LRTIs in 2019. This large difference
      can be explained by the fact that the majority of miners in Ghana are males; 2.0% of all males work as
      miners, while 0.6% of all females mine (Rufai et al., 2014). Mining creates a lot of fine dust particles,
      making miners very susceptible to pneumoconiosis, a lung disease (Centers for Disease Control and
      Prevention, 2021). In addition, acute RTIs caused 13% of all child deaths in 2018 (Obodai et al., 2018).`,
			`Treatment for RTIs depends on the specific type of infection and its severity. Viral RTIs, or RTIs caused
      by viruses, are typically managed with supportive care such as rest, hydration, and over-the-counter
      medications to relieve symptoms. Antibiotics may be prescribed for bacterial RTIs (not viral RTIs, since
      viruses aren’t affected by antibiotics as they aren’t alive), and they should be used according to a
      medical professional’s exact instructions to prevent antibiotic resistance from the bacteria. In severe
      cases or when complications arise, hospitalization and advanced medical interventions may be
      necessary.`,
			`Despite their prevalence, there are ways of preventing many types of RTIs. For example, you can be
      vaccinated against respiratory diseases caused by COVID-19, influenza, measles, and more (Centers
      for Disease Control and Prevention, 2023). Also, it should be a general rule of thumb to maintain good
      hygiene by washing your hands, to keep a safe distance from people who constantly cough and
      sneeze, and to follow safety precautions of areas you visit.`,
			`Respiratory tract infections represent a significant public health challenge with far-reaching
      implications for individuals and communities worldwide. Through education, advocacy, and support,
      organizations like the OKB Hope Foundation are working to address the challenges posed by RTIs and
      promote respiratory health for all.`,
		],
		visible: true,
	},
	{
		id: 17,
		title: "Impact Report 2023",
		link: "https://drive.google.com/file/d/1DWbYwxY_TiruQggkU9q_BYxa2MdL8jc7/view?usp=sharing",
		image: news17,
		date: "06/20/2024",
		category: "News",
		details: [
			"This is the Impact Report for OKB Hope Foundation for the year of 2023. It details the work that the foundation has done with services including but not limited to Hope Health Center and Hope Health Initiatives in the past year.",
			"Hope Health Center was established in response to the pressing need for accessible diagnostics in urban areas. This year, we made a strategic investment in establishing a diagnostic center to cater to the healthcare needs of individuals living in the city. We aim to offer affordable diagnostic services to individuals residing in urban communities, ensuring they have access to essential healthcare resources.",
			"Hope Health Initiative was founded with the mission of enhancing healthcare accessibility in rural and underserved areas throughout Ghana. Our flagship initiative, the Hope Health Van, is equipped with essential medical supplies, medications, and experienced healthcare professionals. We aim to bridge the gap in healthcare access by bringing vital medical services directly to communities lacking nearby healthcare facilities.",
      "To learn more about our impact, please click the link below to view the full report.",
		],
		visible: true,
	},
	{
		id: 18,
		title: "",
		link: "",
		image: "",
		date: "",
		category: "News",
		details: [""],
		visible: false,
	},
	{
		id: 19,
		title: "",
		link: "",
		image: "",
		date: "",
		category: "News",
		details: [""],
		visible: false,
	},
];

// sort and filter data
const sortedNewsData = () => {
	// filter based on visible = true
	const filteredNewsData = MainNewsData.filter(
		(article) => article.title && article.visible === true
	);

	// sort article reverse chronologically
	const dateSortedNewsData = filteredNewsData.sort((a, b) => {
		// parse article dates
		const dateA = new Date(a.date);
		const dateB = new Date(b.date);

		const dateComparison = dateB - dateA;
		return dateComparison;
	});

	return dateSortedNewsData;
};

export const NewsData = sortedNewsData();
