//imports
import { NavLink } from "react-router-dom";
import "./OurImpactPage.css";
import stories1 from "../../Assets/OurImpact/impact-6.png";
import { sdgGoalData } from "../../Data/OurImpactData/SDGGoalData";
import ReactGA from "react-ga";
import { OurImpactData } from "../../Data/OurImpactData/SDGGoalData";
import Communities from "../../common/Communities";
import Map from "../../common/Map/map";

//main
export default function OurImpactPage() {
  // Track page view when the component mounts
  ReactGA.pageview(window.location.pathname + window.location.search);

  // sdg goal data
  const {
    peopleServed,
    ruralCommunitiesReached,
    healthAdvocatesTrained,
    healthHubsEstablished,
  } = sdgGoalData;

  // add commas
  function numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // center of map
  const center = { lat: 6, lng: -1.5258 };

  //return
  return (
    <div className="large-screen">
      <div className="home-header-outer">
        <div className="impact-header">
          <div className="impact-title">Our Impact</div>
        </div>
      </div>

      <div className="our-impact-body">
        <div className="what-body-section">
          <div className="what-section-left margins-auto">
            <h2 className="what-section-left-header">Life Saving Treatment</h2>

            <div className="what-section-text">
              <div className="see-how-we-do">
                Our Hope Health Van equipped with medical supplies and equipment
                that goes to the underserved and remote areas in Ghana to
                provide medical services for the people at little or no cost.
                Since the launch of our van in February 2022, we have served
                more than 5,000 underserved and rural Ghanaians across 52 rural
                communities. Our Hope Health Van ameliorate the issue of
                accessbility by bringing healthcare to people's doorsteps from
                one community to another.
              </div>

              <div className="learn-more-container">
                <NavLink
                  className="learn-more-button"
                  to="https://youtu.be/kOsuQyI2as0"
                  target="_blank"
                >
                  LEARN MORE
                </NavLink>
              </div>
            </div>
          </div>

          <div className="what-section-right">
            <img
              className="what-stories-image"
              src={stories1}
              alt="what we do"
            />
          </div>
        </div>
      </div>
      <div className="impact-communities">
        <h2 className="what-section-left-header">
          Communities We Serve
        </h2>
        <div className="spots-map-container">
          <Map spots={OurImpactData} zoom={8} center={center} />
          <div className="communities-wrapper">
            <Communities />
          </div>
        </div>
      </div>

      <div className="impact-section-two">
        <div className="impact-two-left">
          <div className="sdg-container">
            <div className="sdg-current-container" id="sdg-1">
              <div className="sdg-current-value">
                {numberWithCommas(peopleServed)}
              </div>
              <div className="people-helped">individuals served</div>
            </div>

            <div className="sdg-current-container" id="sdg-2">
              <div className="sdg-current-value">
                {numberWithCommas(ruralCommunitiesReached)}
              </div>
              <div className="people-helped">rural communities reached</div>
            </div>

            <div className="sdg-current-container" id="sdg-3">
              <div className="sdg-current-value">
                {numberWithCommas(healthAdvocatesTrained)}
              </div>
              <div className="people-helped">health advocates trained</div>
            </div>

            <div className="sdg-current-container" id="sdg-4">
              <div className="sdg-current-value">
                {numberWithCommas(healthHubsEstablished)}
              </div>
              <div className="people-helped">health hubs established</div>
            </div>
          </div>
        </div>
      </div>

      <div className="impact-message">
        <div className="impact-message-image-navlink">
          <iframe
            className="impact-message-video"
            src="https://www.youtube.com/embed/mPJg4891lG8"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="impact-message-container">
          <h2 className="impact-message-text-last">
            A Heartfelt Message From The Mobia Community
          </h2>
          <div className="impact-section-text">
            OKB Hope Foundation brings transformative healthcare services to the
            doorsteps of the Mobia Community, a farming community located in the
            Ashanti Region. OKB Hope Foundation provided health education, early
            screenings, and medications to over 200 community members.
          </div>
        </div>
      </div>
    </div>
  );
}
