export const sdgGoalData = {
	peopleServed: 6500,
	ruralCommunitiesReached: 63,
	healthAdvocatesTrained: 20,
	healthHubsEstablished: 2,
};

export const OurImpactData = [
	{
		id: 1,
		name: "Apatam",
		overview: "Nestled within the Offinso North District in the Ashanti Region, the Apatam community emerges as a close-knit settlement, home to an estimated population of approximately 200 inhabitants. Notably, the majority of Apatam’s residents are of northern descent.\nApatam, like many other underserved communities, faces a significant challenge when it comes to healthcare access. This close-knit community lacks any local health facility, and community members are confronted with a daunting 40-minute motorcycle journey to reach the nearest healthcare provider. The primary obstacle to their access is the poor state of the road network, which underscores the critical need for improved healthcare infrastructure in this area.\nMalaria is a pressing concern in Apatam, and the lack of immediate healthcare services makes addressing this and other health issues a formidable challenge. Despite these challenges, the indomitable spirit of the community persists.\nThe livelihood of Apatam's residents revolves around agriculture, with farming as their primary occupation. The community's fields yield various essential crops, including cassava, yam, plantain, cashew, rice, tomatoes, and pepper. These hardworking individuals play a vital role in contributing to the region's agricultural output.",
		lng: -2.2320200,
		lat: 4.9189900,
		population: 200,
		water: "Standing water",
		electricity: "No",
		education: "None",
		screened: 185,
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 2,
		name: "Tokwai",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.84227,
		lat: 5.47514,
		population: 1480,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "Farming",
		trained: 4,
		facility: "45 minute drive"
	},
	{
		id: 3,
		name: "Ahenbronum",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.616538185014343,
		lat: 6.732616299697398,
		population: 2556,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: 4,
		facility: "NA"
	},
	{
		id: 4,
		name: "Yawberima",
		overview: "There is no overview yet, waiting for data.",
		lng: -2.0761690591843447,
		lat: 6.606973355303299,
		population: 755,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: 4,
		facility: "30 minute drive"
	},
	{
		id: 5,
		name: "Kwabeng",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.590444989796424,
		lat: 6.315800970357893,
		population: 956,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 6,
		name: "Akutuase",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.4491009138550377,
		lat: 5.232952005799421,
		population: 2691,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 7,
		name: "Ananekrom",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.0142465177660083,
		lat: 6.915423835590385,
		population: 785,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "CHPS"

	},
	{
		id: 8,
		name: "Nyinapomase",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.22086778795389167,
		lat: 5.587783152757901,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 9,
		name: "Dome Tweneboase",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.2598145426042117,
		lat: 5.576667206477104,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 10,
		name: "Hwidiem",
		overview: "There is no overview yet, waiting for data.",
		lng: -2.358756090485741,
		lat: 6.937647965531249,
		population: 4806,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 11,
		name: "Nkwadum",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.22740014755387128,
		lat: 5.572550478946731,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 12,
		name: "Woraponso",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.2764042523702979,
		lat: 6.880184459329442,
		population: "NA",
		water: "NA",
		electricity: "Yes",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "CHPS"
	},
	{
		id: 13,
		name: "Mobia",
		overview: "There is no overview yet, waiting for data.There is no overview yet, waiting for data.",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 887,
		water: "Borehole",
		electricity: "No",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "No"
	},
	{
		id: 14,
		name: "Bodomase",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 1860,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 15,
		name: "Worawoso",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.2555433201229158,
		lat: 6.893873663144239,
		population: 683,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "No"
	},
	{
		id: 16,
		name: "Ejura",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.3585939216649694,
		lat: 7.38459641691365,
		population: 136672,
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 17,
		name: "Besoro",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.241444,
		lat: 6.900917,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 18,
		name: "Srentiatia",
		overview: "There is no overview yet, waiting for data.",
		lng: -2.060083,
		lat: 7.429806,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 19,
		name: "Agbogbloshie",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.215556,
		lat: 5.550556,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 20,
		name: "Akuse",
		overview: "There is no overview yet, waiting for data.",
		lng: 0.122778,
		lat: 6.096944,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 21,
		name: "Manya Krobo (Odumase Krobo)",
		overview: "There is no overview yet, waiting for data.",
		lng: 0.013389,
		lat: 6.148361,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 22,
		name: "Buoyem",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.961111,
		lat: 7.666111,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 23,
		name: "Oyoko",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.571722,
		lat: 6.551889,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 24,
		name: "Bodwease",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.3375,
		lat: 6.920111,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 25,
		name: "Akrochyire",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.338944,
		lat: 6.931722,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 26,
		name: "Agona",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.486972,
		lat: 6.934611,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 27,
		name: "Sekyere Afram Plains",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.950306,
		lat: 7.126528,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 28,
		name: "Wenamda",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.022222,
		lat: 7.00275,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 29,
		name: "Menam",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.23925,
		lat: 6.77525,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 30,
		name: "Domeabra",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.137556,
		lat: 6.724056,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 31,
		name: "Kyebi",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.784306,
		lat: 7.106583,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 32,
		name: "Drobonso",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.124139,
		lat: 7.074889,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 33,
		name: "Ningo Prapram",
		overview: "There is no overview yet, waiting for data.",
		lng: 0.179417,
		lat: 5.754972,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 34,
		name: "Brenu - Ampenyi",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.426667,
		lat: 5.068306,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 35,
		name: "Ayigya Market",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.572583,
		lat: 6.689722,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 36,
		name: "Abinchi",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.615222,
		lat: 6.680639,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 37,
		name: "Ejisu",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.470667,
		lat: 6.722583,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 38,
		name: "Asawasi",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.610222,
		lat: 6.699222,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 39,
		name: "Makola community",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.206944,
		lat: 5.54775,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 40,
		name: "Amatena",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.1385,
		lat: 6.779028,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 41,
		name: "Susankyi",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.584944,
		lat: 6.682944,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 42,
		name: "Barekumaa",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.724028,
		lat: 6.851917,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 43,
		name: "Ablekuma (Kehillah Center and Public Library)",
		overview: "There is no overview yet, waiting for data.",
		lng: -0.294556,
		lat: 5.625639,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 44,
		name: "Top-High (Joy Fm, Kumasi)",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.578722,
		lat: 6.687694,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 45,
		name: "Adum (RMSC of Forestry Commission)",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.624361,
		lat: 6.688972,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 46,
		name: "Adum (Opoku Trading)",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.624194,
		lat: 6.690139,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 47,
		name: "PIWC Suame",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.635,
		lat: 6.720972,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 48,
		name: "Edwenase Kwadaso (Charismatic Miracle Chapel)",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.654167,
		lat: 6.677361,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 49,
		name: "Kumasi Maakro School",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.63525,
		lat: 6.73025,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 50,
		name: "Sunyani (Emmanuel Assemblies of God Church)",
		overview: "There is no overview yet, waiting for data.",
		lng: -2.319194,
		lat: 7.35125,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 51,
		name: "Ejisu Market",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.471278,
		lat: 6.7215,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 52,
		name: "Ayigya (Bethel Assemblies of God)",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.571722,
		lat: 6.688444,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 53,
		name: "St. Hubert Seminary SHS",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.649694,
		lat: 6.660694,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 54,
		name: "Kumasi Anglican SHS",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.608722,
		lat: 6.692833,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 55,
		name: "KumasI Wesley Girls High School",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.619667,
		lat: 6.717444,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 56,
		name: "Asanteman Senior High School",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.628333,
		lat: 6.709167,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 57,
		name: "Anhwerekrom",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.726361,
		lat: 7.012806,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	},
	{
		id: 58,
		name: "Denkyemuoso",
		overview: "There is no overview yet, waiting for data.",
		lng: -1.685806,
		lat: 6.692472,
		population: "NA",
		water: "NA",
		electricity: "NA",
		education: "NA",
		screened: "NA",
		occupation: "NA",
		trained: "NA",
		facility: "NA"
	}
]
