//imports
import { NewsData } from "../../Data/NewsData/newsData.js";
import "./NewsPage.css";
import ReactGA from "react-ga";

import NewsCard from "./NewsCard/index.js";
import { useState } from "react";

// mapping which categories to show when a tab is selected
const selectedTabMapping = {
	all: ["news", "awards", "education", "events", "blog"],
	news: ["news", "awards", "education", "events"],
	blog: ["blog"],
};

//main
export default function NewsPage() {
	// Track page view when the component mounts
	ReactGA.pageview(window.location.pathname + window.location.search);

	// states
	const [selected, setSelected] = useState("all");

	// filter data based on selected tab
	const filteredNewsData = NewsData.filter((article) =>
		selectedTabMapping[selected].includes(article.category.toLowerCase())
	);

	//return
	return (
		<div className="large-screen">
			<div className="home-header-outer">
				<div className="news-header">
					<div className="impact-title">News & Blog</div>
				</div>
			</div>
			<div className="events-page">
				<div className="news-and-blog-headers">
					<h1
						className={`nb-all-header ${
							selected === "all" && "nb-selected"
						}`}
						onClick={() => setSelected("all")}
					>
						All
					</h1>
					<h1
						className={`nb-news-header ${
							selected === "news" && "nb-selected"
						}`}
						onClick={() => setSelected("news")}
					>
						News
					</h1>
					<h1
						className={`nb-blog-header ${
							selected === "blog" && "nb-selected"
						}`}
						onClick={() => setSelected("blog")}
					>
						Blog
					</h1>
				</div>
				<div className="event-list">
					{filteredNewsData.length > 0 ? (
						filteredNewsData.map((article) => (
							<NewsCard key={article.id} article={article} />
						))
					) : (
						<div className="no-events">No articles found.</div>
					)}
				</div>
			</div>
		</div>
	);
}
