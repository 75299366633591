// imports
import osei1 from "../../Assets/WhoWeAre/osei-1.png";
import benj2 from "../../Assets/WhoWeAre/benj-2-3.png";
import kwam3 from "../../Assets/WhoWeAre/kwam-3-3.png";
import just4 from "../../Assets/WhoWeAre/just-4-1.png";
import emma5 from "../../Assets/WhoWeAre/emma-5-2.png";
import pris6 from "../../Assets/WhoWeAre/pris-6-2.png";
import mars10 from "../../Assets/WhoWeAre/mars-10-2.png";
import chri11 from "../../Assets/WhoWeAre/chri-11-2.png";
import edga12 from "../../Assets/WhoWeAre/edga-12-2.png";
import mich13 from "../../Assets/WhoWeAre/mich-13-2.png";
import shiv14 from "../../Assets/WhoWeAre/shiv-14-2.png";
import pams15 from "../../Assets/WhoWeAre/pams-15-2.png";
import kwam16 from "../../Assets/WhoWeAre/kwam-16-2.png";
import sand17 from "../../Assets/WhoWeAre/sand-17-2.png";
import jade18 from "../../Assets/WhoWeAre/jade18.png";
import kwadwo19 from "../../Assets/WhoWeAre/kwadwo19.png";
import edward20 from "../../Assets/WhoWeAre/edward20.png";
import letim6 from "../../Assets/WhoWeAre/leti-m6-1.png";
import tracm7 from "../../Assets/WhoWeAre/trac-m7-1.png";
import constm8 from "../../Assets/WhoWeAre/const-m8-1.png";
import gilbm9 from "../../Assets/WhoWeAre/gilb-m9-1.png";
import rebed9 from "../../Assets/WhoWeAre/rebe-d9-1.png";
import ruthv4 from "../../Assets/WhoWeAre/ruth-v4-1.png";

// {option:[{name, shortName, position, image, linkedin, description}, {}], ...}
export const OurTeamData = {
  administrative: [
    {
      id: "a1",
      name: "Osei Kwadwo Boateng",
      position: "Founder",
      image: osei1,
      linkedin: "https://www.linkedin.com/in/oseiboateng/",
      description: [
        "I devote my life’s work to making sure quality healthcare is accessible to everyone irrespective of their socio-economic background.",
        "Osei Boateng was born in Kumasi, Ghana, and lived there for 19 years before heading to Cornell University, where he received his Bachelor of Science in Human Biology, Health, and Society with minors in Business and Gerontology and a Master's degree in Healthcare Administration from Cornell University.",
        "Osei is a Cornell Tradition Fellow alum and the Cornell Senior Recognition Award recipient. He also won the Student Excellence Award in Community Engagement and Honorarium from the Maribel Garcia Community Spirit in 2017 for his international service in Ghana. He was selected as an Echoing Green finalist in 2022, 2023 Young Global Changer, and 2023 CNN Hero.",
      ],
    },
    {
      id: "a2",
      name: "Benjamin Nyarko",
      position: "Administrative Services",
      image: benj2,
      linkedin: "https://www.linkedin.com/in/benjamin-nyarko-4a4a97143/",
      description: [
        "​​Benjamin Nyarko is a product from KNUST, and holds a bachelor’s degree in Aerospace Engineering. He’s currently an Aircraft Maintenance Trainee at Africa World Airlines. He has a keen interest in Humanitarian duties and loves serving the youth in all manner of aids.",
      ],
    },
    {
      id: "a3",
      name: "Kwame Adu Boampong",
      position: "Director of Operations",
      image: kwam3,
      linkedin: "https://www.linkedin.com/in/kwame-adu-boampong-639a729b/",
      description: [
        "Kwame Adu Boampong is the Director of Operations at OKB. He has experience as a Development Educationist and Data Clerk. He holds a degree in development education from the University for Development Studies.",
        "Before joining OKB, Kwame served as a development educationist on the Community Child Protection Program, a project run by the Department of Community Development, located in the Wa Municipal (Upper West Region, Ghana). In this role, he also worked in partnership with Catholic Relief Service, on the project “Savings and Internal Lending Community” in the Upper West region in Ghana.",
        "In addition to his project and operational experience, he has also worked as Data Clerk and a Field Operation Assistant in the Department of Transportation, Ejisu Municipal Assembly in the Ashanti Region of Ghana.",
      ],
    },
    {
      id: "a4",
      name: "Justice Awulley-Quaye",
      position: "Director of Finances",
      image: just4,
      linkedin: "https://www.linkedin.com/in/justice-awulley-quaye-2a0a96164/",
      description: [
        "Justice Awulley-Quaye is a Physician Assistant and the Director of Finances at OKB. In this role, he offers medical assistance to OKB patients and oversees the financial maintenance of the organization. He recently completed a year of internship training at Nsawam Government Hospital and currently works full-time at Fast Care Clinic. He has a Bachelor’s degree in physician assistantship from Central University, Ghana.",
        "Aside from having an interest in the physical well-being of people, Justice is also very passionate about music. Justice is a pianist and practices his love for the instrument by entertaining loved ones and teaching others to play the piano. Justice also participates in community-based education activities to provide culturally relevant health information, answer individual questions about health status, and provide treatment options for a variety of health issues.",
      ],
    },
  ],
  medical: [
    {
      id: "m1",
      name: "Emmanuel Addo",
      position: "Doctor of Optometry",
      image: emma5,
      linkedin: "",
      description: [
        "​​Emmanuel Aduamoah Addo currently works at Kwame Nkrumah University of Science and Technology (KNUST) optometry clinic as an optometrist and clinical research assistant at the Kwame Nkrumah University of Science and Technology. His research focuses on the assessment of night vision problems among drivers in Ghana. Emmanuel is very passionate about the serving underserved and the vulnerable population.",
      ],
    },
    {
      id: "m2",
      name: "Priscilla Osei-Bonsu",
      position: "Clinical Psychologist",
      image: pris6,
      linkedin: "https://www.linkedin.com/in/priscilla-osei-bonsu-21b088131/",
      description: [
        `An innovative social development professional with experience in public and non-profit sectors in the areas of public education and policy, social development, and mental health. My passion lies in starting right and redefining the "marred places". I am particularly interested in exploring various avenues of optimal functioning for every individual. Ultimately, as a mental health practitioner, I seek to ensure the relevance of psychological health in all fields of work.`,
      ],
    },
    {
      id: "m6",
      name: "Leticia Agyemang",
      position: "Nurse",
      image: letim6,
      linkedin: "",
      description: [],
    },
    {
      id: "m7",
      name: "Tracy Antwi Bosiako",
      position: "Lab Technician",
      image: tracm7,
      linkedin: "",
      description: [],
    },
    {
      id: "m8",
      name: "Constance Adjei-Boafo",
      position: "Physician Assistant",
      image: constm8,
      linkedin: "",
      description: [],
    },
    {
      id: "m9",
      name: "Gilbert Adu Nyarko",
      position: "Lab Technician",
      image: gilbm9,
      linkedin: "",
      description: [],
    },
  ],
  directors: [
    {
      id: "d1",
      name: "Marsha Sinanan-Vasishta",
      position: "CNO Mount Sinai Morningside",
      image: mars10,
      linkedin:
        "https://www.linkedin.com/in/marsha-sinanan-vasishta-msn-mba-rn-nea-bc-cpxp-56753315a/",
      description: [
        "​Marsha Sinanan-Vasishta, MBA, MSN, RN, NEA-BC, is the Interim Vice President, Nursing/Chief Nursing Officer for Mount Sinai Morningside. In this role, Ms. Sinanan-Vasishta is responsible for operations, fiscal management, and delivery of safe, timely, efficient, and equitable patient-centered care.",
        "An accomplished nurse leader, Ms. Sinanan-Vasishta is skilled at collaborating with, physicians, and clinical leaders within diverse settings and is an expert in driving employee engagement.",
        "Prior to working at Mount Sinai, Ms. Sinanan-Vasishta was a Director of Nursing at NewYork-Presbyterian/Allen Hospital where she provided oversight to the Emergency Department, Behavioral Health, the Dan and Jane Och Spine Hospital, and the Geriatric/Hospitalist Unit where she helped to lead and achieve several system wide quality, patient safety and patient experience goals.",
        "Ms. Sinanan-Vasishta holds a Master of Business Administration with a concentration in Health Care Management as well as a Masters of Science in Nursing from the University of Phoenix. She is board certified as a Nurse Executive, a Certified Patient Experience Professional since 2016, a member of the Transcultural Nursing Society and the International Honor Society for Nursing. Throughout her career, Ms. Sinanan-Vasishta has encouraged, supported and guided staff to excellent nursing practice, patient centered care through Magnet principles, continuous learning, and creative thinking.",
      ],
    },
    {
      id: "d2",
      name: "Dr. Chris Kwaku Oppong",
      position: "Specialist Emergency Physician",
      image: chri11,
      linkedin: "",
      description: [
        "A product of Kwame Nkrumah University of Science and Technology (KNUST) School of Medical Sciences and a Fellow of the Ghana College of Physicians and Surgeons.",
        "Dr. Chris is the head of the Emergency Medicine Directorate at the Komfo Anokye Teaching Hospital (KATH) . He is a Part time Lecturer at the College of Health Science (KNUST), and Instructor at the Ghana National Ambulance Training School which trains Emergency medical technicians.",
        "Dr. Chris serves as a faculty member of the Ghana College of Physicians and Surgeons Emergency Medicine Department. The faculty trains Emergency medicine specialists under the Ghana College of Physicians and Surgeons.",
        "​He is married and has 2 sons. He enjoys watching football, documentaries and doing research.",
      ],
    },
    {
      id: "d3",
      name: "Edgar Akuffo Addo",
      position: "Medical Student, University of Toronto",
      image: edga12,
      linkedin: "https://www.linkedin.com/in/edgar-akuffo-addo-a6aba445/",
      description: [
        "Edgar is a medical student at the Temerty Faculty of Medicine at the University of Toronto. He graduated from Cornell University with a Bachelor of Science in Human Biology, Health and Society and a master’s degree in Health administration.",
        "Prior to medical school, Edgar worked with the Department of Health and Wellness, Government of Nova Scotia. He also served as an administrative fellow at University Hospital in Newark, NJ. Edgar currently sits on the board of the Canadian Federation of Medical Students and is very keen on helping non-profit organizations develop innovative and sustainable funding streams.",
      ],
    },
    {
      id: "d4",
      name: "Michael Wiafe Akenteng",
      position: "Registered Dietician",
      image: mich13,
      linkedin:
        "https://www.linkedin.com/in/michael-wiafe-akenteng-phd-30702753/",
      description: [
        "​​Mr. Michael Wiafe Akenteng is a registered dietician at Jireh Nutrition and Dietetics Consult in Kumasi – Ghana. He completed his BSc in Community Nutrition at University for Development Studies and currently pursuing his Mphil in Human Nutrition and Dietetics at Kwame Nkrumah University of Science and Technology.",
      ],
    },
    {
      id: "d5",
      name: "Dr. Shivaun Leonard",
      position: "Aquaculture Business Professional",
      image: shiv14,
      linkedin: "https://www.linkedin.com/in/shivaun-leonard-b6a888a/",
      description: [
        "​​Globally networked aquaculture business professional in the business model, production system design, development, implementation, and management of vertically integrated commercial private sector aquaculture ventures in Africa.",
        "​Skilled at finding and developing interconnections within and between sectors, building networks, partnerships, coalitions, resource mobilization, and scaling aquaculture products through smart investing, private sector engagement, and scaling innovative research. Pragmatic integrated systems approach and dialoguing across commodities.",
      ],
    },
    {
      id: "d6",
      name: "Pam Silverstein",
      position: "Entrepreneur, Cornell University",
      image: pams15,
      linkedin: "",
      description: [
        "​Pam Silverstein graduated with an MBA from Cornell in the 70’s and have been an entrepreneur since. She has created many companies such as Ironics which was renamed Pathlight and was later sold to public company.",
        "​Pam have mentored both startups and established companies since 1976 with expertise in administration, finance, operations, and manufacturing. Pam is currently a Entrepreneur in Residence at Life Changing Lab at Cornell University.",
      ],
    },
    {
      id: "d7",
      name: "Kwame Kyeremateng Mensah-Aborampah",
      shortName: "K.K. Mensah-Aborampah",
      position: "CEO of K. Kyeremateng Company Ltd",
      image: kwam16,
      linkedin: "",
      description: [
        "​Mr. Kwame Kyeremateng is the CEO of K. Kyeremateng Company Ltd and the director of Trans Chik-Chak Company Ltd (Road construction and consultants). He is a graduate of Kwame Nkrumah Science and Technology (KNUST), having received his bachelor’s degree in computer science. Mr. Kyeremateng has held various managerial and executive positions including President of Rotary Club of Kumasi East, President of Progressive Road Contractors Association, and Assistant Director of Rotary Community Corps.",
        "He currently serves as the Chairman of International Relations of Rotary club of Kumasi East, Patron of Progressive Road Contractors Association and Global Director of Career Guidance and Counseling for St. John’s Senior High School Alumni.",
      ],
    },
    {
      id: "d8",
      name: "Sandy Khaund",
      position: "CEO of Credenza",
      image: sand17,
      linkedin: "https://www.linkedin.com/in/sandyk/",
      description: [
        "​Sandy Khaund is a serial entrepreneur/intrapreneur with 25 years of experience across software development, product marketing, business development, and strategy consulting..  He has held managerial and executive positions at five Fortune 500 companies (including Intel, Microsoft, and Warner Media) and served as a C-Level executive at three acquired startups (Piczo, Instadium, and UPGRADED). He has also founded startups in the Streaming Media, EdTech, and Live Event space.",
        `Most recently, Sandy was founder and CEO of UPGRADED, a blockchain ticketing company that was acquired in 2018 by Live Nation/Ticketmaster, where he subsequently took on a role as Vice President and General Manager.  Currently, he is the CEO of Credenza a platform that empowers sports teams and athletes to seamlessly integrate Web3 technologies into existing operations to enhance direct-to-fan relationships. In addition, he serves as a General Partner at MindSpring Capital, a global sports tech investment and advisory firm, and is on the Board of Directors at the Maker Foundation, an organization developing "DeFi" technology for borrowing, savings, and a stable cryptocurrency on the Ethereum blockchain.`,
        "Sandy has a BSEE & M.Eng from Cornell University, a BA in Physics from Ithaca College, and an MBA from Wharton.",
      ],
    },
    {
      id: "d9",
      name: "Dr. Rebecca Klege",
      position: "Director of Research at Henry J. Austin Health Center (HJAHC)",
      image: rebed9,
      linkedin: "",
      description: [
        `Dr. Klege brings extensive knowledge and a strong commitment to health and development initiatives. Since July 2021, she has served as the Director of Research at Henry J. Austin Health Center (HJAHC), applying over a decade of experience in research and teaching. Her expertise includes randomized control trials, field and lab experiments, and qualitative studies on a wide range of topics. Dr. Klege's international research includes significant projects in Ghana, South Africa, Rwanda, and Costa Rica.`,
        `Dr. Klege earned her Ph.D. in Economics from the University of Cape Town, South Africa, with additional specializations from the University of Gothenburg, Sweden. Prior to joining HJAHC, she was the research manager for Women in Environmental Economics for Development (WinEED) in Colombia. Her career highlights include securing numerous research grants and actively participating in international conferences as a panelist, discussant, and presenter. Additionally, she has published in peer-reviewed economics journals and serves as a reviewer for several academic journals.`,
        `Outside of her professional work, Dr. Klege enjoys hiking, photography, traveling, and spending time with her family. Her rich research experience and passion for improving health outcomes make her a valuable member of the OKB Hope Foundation board, advancing its mission to support underserved communities.`,
      ],
    },
  ],
  volunteers: [
    {
      id: "v1",
      name: "Jade Kissi",
      position: "Research Consultant",
      image: jade18,
      linkedin: "",
      description: [
        "Jade Kissi, MPH, is a research consultant at OKB. In this role, she analyzes OKB health data, creates supporting infographics, and provides supporting research leadership on journal publications, internal publications, and grant applications. Jade currently works as a Child Health Program Associate for the Center for Health Care Strategies, a healthcare policy think tank dedicated to improving healthcare outcomes for government insurance recipients.",
        "Jade earned a master’s of public health in healthcare management from Boston University and a bachelor’s degree in the sociology of health and medicine from the University of Michigan.",
        "Jade has research and project management experience working with American and Ghanaian health departments, pediatric hospital systems, community health departments, and international non-profits. Jade is committed to utilizing her passion for problem-solving, research, leadership, and policy to pursue equitable healthcare solutions.",
        "Outside of her professional duties, Jade enjoys spending time with her family, tending to her vegetable garden, traveling, going to concerts, singing in her church choir, teaching Sunday school, and cooking and dancing to afro beats and reggaeton!",
      ],
    },
    {
      id: "v2",
      name: "Kwadwo Owusu Ansah",
      position: "Strategy Officer",
      image: kwadwo19,
      linkedin: "",
      description: [
        "Kwadwo Owusu Ansah, a native of Ghana, is a dedicated healthcare professional with a strong commitment to serving underserved populations. He earned his Bachelor's degree in Cell and Developmental Biology at the University of Rochester, where his passion for healthcare equity was ignited. Following this, he embarked on a journey to expand his knowledge and impact, interning at UT MD Anderson and Mount Sinai Health System.",
        "Currently pursuing his medical degree at the University of North Carolina School of Medicine, Kwadwo remains unwavering in his dedication to addressing healthcare disparities and improving access for those in need. His goal is to become a physician who not only treats illnesses but also works tirelessly to eliminate the barriers that underserved communities face in accessing quality healthcare.",
      ],
    },
    {
      id: "v3",
      name: "Edward Sarfo",
      position: "Innovation Officer",
      image: edward20,
      linkedin: "",
      description: [
        "Edward Sarfo, a native of Ghana, is currently pursuing his medical degree at the Icahn School of Medicine at Mount Sinai in New York City, USA. He holds a Bachelor of Science in Biotechnology from the City University of New York, City College. His commitment to scientific advancement led him to complete a research fellowship at the National Institute of Health's Vaccine Research Center, where he collaborated with globally renowned scientists.",
        "Edward is a former National Institute of Health Academy fellow in Health disparities. He has also been selected as a Fellow in the Diversity Innovation Hub (Mt. Sinai) and HealthTech For Medicaid (California), further demonstrating his commitment to healthcare innovation. Edward focuses on building strategic partnerships and driving innovative solutions in underserved populations.",
      ],
    },
    {
      id: "v4",
      name: "Ruthly Cadestin",
      position: "Partnership Consultant",
      image: ruthv4,
      linkedin: "",
      description: [
        `Ruthly Cadestin is an advocate, storyteller, creator, and servant for the global community. She has a strong interest in international development, post-conflict and reconciliation, and anti-human trafficking efforts. Ruthly Cadestin is dedicated to maintaining peace, promoting sustainable development, and protecting human rights. She assisted in many projects pertaining to international development and studied in Thailand and Bosnia-Herzegovina. Ms. Ruthly Cadestin also worked on several initiatives focused on community and women empowerment, education reform, economic equality, and human rights.`,
        `Ms. Ruthly Cadestin is a graphic designer and an artist. She attended King's College, and there she received a B.S. in International Business and two minors in History and Economics. Furthermore, she received her M.A. at Seton Hall University's School of Diplomacy and International Relations, in which she specialized in Human Rights and International Law and Post-Conflict Reconstruction and Sustainability. Currently, she sits on the Board of Directors of Investor Advocates for Social Justice (IASJ), an organization representing investors with faith-based values who seek to leverage their investments to advance human rights, climate justice, racial equity, and the common good. Ms. Ruthly Cadestin is dedicated to promoting the common good for global citizens through economic, social, political, and artistic avenues.`,
        `All in all, Ms. Ruthly Cadestin has played many roles, which include being a Community Organizer for Common Cause New York, an Executive Editor for the Journal of Diplomacy and International Relations, and an English Teacher Volunteer with the Rotaract Chapter of the Webster University of Thailand. Lastly, she is the former Treasurer, State and Regional Board Member of Citizen Action of New York, a grassroots organization taking on issues that are at the center of transforming society.`,
      ],
    },
  ],
};
