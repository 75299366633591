import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { OurImpactData } from '../../Data/OurImpactData/SDGGoalData';
import "./communities.css";


const Communities = () => {
    // console.log("ourdata: ", OurImpactData)
    const navigate = useNavigate();

    const sortedCommunities = [...OurImpactData].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className='communities-list-wrapper'>
            <div className='community-list-header'>
                Find a Community
            </div>
            <div className='community-list'>
                {sortedCommunities.map((community) => (
                    <div className="community-item" key={community.id}>
                        {/* <Link className='community-link' to={`/communities/${community.id}`}>{community.name}</Link> */}
                        <Link className='community-link' to="#">{community.name}</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Communities;
